import { useMemo } from 'react';
import axios from 'axios';
// EmPath UI Components
import { encodeURLParam } from '@empathco/ui-components/src/helpers/path';
// local imports
import useCustomerSettings from './customer';

// eslint-disable-next-line no-process-env
export const API_HOST = window.API_HOST || '';
export const API_URI = '/api' as const;
export const API_BASE = API_HOST ? `https://${API_HOST}${API_URI}` as const : API_URI;

// Authentication
export const API_USER_TOKEN_BY_SSO_DEFAULT = '/token-by-auth-link/' as const;
export const API_USER_TOKEN_BY_SSO_SSO = '/token-by-sso/' as const;
export const API_USER_ME = '/users/me/' as const;
export const API_EMAIL_AUTH_LINK = '/email-auth-link/' as const;

// Common
export const API_JOB_CATEGORIES = '/v3/common/job_categories/' as const;
export const API_CAREER_LADDERS = '/v3/common/career_ladders/' as const;
export const API_ORGS = '/v3/common/orgs/' as const;
export const API_COURSE_PROVIDERS = '/v3/common/course_providers/' as const;
export const API_SUCCESS_STORIES = '/v3/common/success_stories/' as const;
export const API_SKILL_CATEGORIES = '/v3/common/skill_categories/' as const;
// Common - Skills
export const API_SKILLS = '/v3/common/skills/' as const;
export const API_SKILLS_IN_DEMAND = `${API_SKILLS}in_demand/` as const;
export const getApiSkill = (skillId: string) => `${API_SKILLS}${encodeURLParam(skillId)}/` as const;
export const getApiSkillEmployees = (skillId: string) => `${getApiSkill(skillId)}employees_counts/` as const;
// Common - Jobs
export const API_JOBS = '/v3/common/jobs/' as const;
export const API_JOB = `${API_JOBS}{args.job_code}/` as const;
export const getApiJob = (roleId: string | number) => `${API_JOBS}${encodeURLParam(roleId)}/` as const;
export const getApiJobMoves = (jobId: number) => `${getApiJob(jobId)}most_common_job_moves/` as const;
export const API_TECHNICAL_CAREER_PATH = '/v3/common/technical_career_path/' as const;
// Common – Countries/States
export const API_COUNTRIES = '/v3/common/countries/' as const;
export const API_STATES = '/v3/common/states/' as const;
// Common – Locations
export const API_LOCATIONS = '/v3/common/locations/' as const;

// My (available for all roles)
export const API_MY_SKILLS = '/v3/my/skills/' as const;
export const API_MY_SELECTED_ACTIVITIES = '/v3/my/selected_activities/' as const;
export const API_MY_SELECTED_ACTIVITY = `${API_MY_SELECTED_ACTIVITIES}{args.activity_id}/` as const;
export const API_MY_INFERRED_SKILLS = '/v3/my/inferred_skills/' as const;
export const API_MY_SKILLS_SET = '/v3/my/set_skills_level/' as const;
export const API_MY_TARGET_SKILLS_SET = '/v3/my/set_skills_target/' as const;
export const API_MY_CURRENT_JOB = '/v3/my/current_job/' as const;
export const API_MY_PREFERENCES = '/v3/my/preferences/' as const;
export const API_MY_SET_PREFERENCES = '/v3/my/set_preferences/' as const;
export const API_MY_SETTINGS = '/v3/my/ui_settings/' as const;
export const API_MY_SETTINGS_UPDATE = '/v3/my/ui_settings/update_settings/' as const;
export const API_MY_NOTIFICATIONS = '/v3/my/notifications/' as const;
export const API_MY_SEARCH = '/v3/my/search/' as const;
export const API_MY_PROFILE_BUILDER_COMPLETE = '/v3/my/profile_builder_complete/' as const;
export const API_EMPLOYEES_CONNECT = '/v3/my/same_job_employees/' as const;
export const API_MY_DATA_STATUS = '/v3/my/cached_data_status/' as const;
export const API_MY_DATA_UPDATE = '/v3/my/update_cached_data/' as const;
export const API_MY_COURSE_ADVISOR_SEARCH = '/v3/my/course_and_skill_advisers_search/' as const;
export const API_MY_COC = '/v3/my/coc/' as const;
export const API_ORG_HIERARCHY = '/v3/my/org_hierarchy/' as const;
export const API_MY_NOTIFICATION_COUNTS = '/v3/my/notifications/counts/' as const;
export const getApiOpenReqs = (roleId: string) => `/v3/my/jobs/${encodeURLParam(roleId)}/open_reqs/` as const;
export const getApiTalentData = (roleId: string) => `/v3/my/talent_data/${encodeURLParam(roleId)}/` as const;
export const API_MY_OPPORTUNITIES = '/v3/opportunity_marketplace/my_opportunities/' as const;
export const API_MY_OPPORTUNITIES_BROWSE = `${API_MY_OPPORTUNITIES}browse/` as const;
export const API_MY_OPPORTUNITY_OWNERS = `${API_MY_OPPORTUNITIES}managers/` as const;
export const API_MY_OPPORTUNITY = `${API_MY_OPPORTUNITIES}{args.opportunity_id}/` as const;
export const API_MY_RESUME_UPLOAD = '/v3/my/resume/upload/' as const;
export const API_MY_RESUME_STATUS = '/v3/my/resume/status/' as const;

// Employee
const API_MY_DASHBOARD = '/v3/employees/dashboard/' as const;
export const API_MY_DASHBOARD_SKILLS = `${API_MY_DASHBOARD}my_skills/` as const;
export const API_MY_DASHBOARD_SUGGESTED = `${API_MY_DASHBOARD}skill_development_recommendations/` as const;
export const API_MY_DASHBOARD_JOBS = `${API_MY_DASHBOARD}open_close_match_jobs/` as const;
export const API_MY_ACTIVITIES = '/v3/employees/activities/' as const; // POST only
export const API_MY_ACTIVITY = `${API_MY_ACTIVITIES}{args.activity_id}/` as const; // PATCH/DELETE only
// Employee Skills Intelligence
export const API_EMPLOYEE_SKILLS_RECOMMENDATIONS = '/v3/employees/skills_intelligence/skills/recommendations/' as const;
// Employee Skills
export const API_EMPLOYEE_SKILLS_BROWSING = '/v3/employees/skills_browsing/skills/' as const;
export const API_EMPLOYEE_SKILLS_EXPORT = `${API_BASE}${API_EMPLOYEE_SKILLS_BROWSING}export/` as const;
export const API_EMPLOYEE_SKILLS = '/v3/employees/skills/' as const;
export const API_EMPLOYEE_SKILL = `${API_EMPLOYEE_SKILLS}{args.skill_id}/` as const;
export const getApiEmployeeSkill = (skillId: string) => `${API_EMPLOYEE_SKILLS}${encodeURLParam(skillId)}/` as const;
export const API_EMPLOYEE_SKILLS_TARGETED = '/v3/employees/skills/targeted/' as const;
export const API_EMPLOYEE_SKILLS_IN_DEMAND = '/v3/employees/skills/in_demand/' as const;
export const getApiSkillDevPlan = (skillId: string) => `${getApiEmployeeSkill(skillId)}dev_plan/` as const;
export const API_EMPLOYEE_SKILL_ADVISORS = `${API_EMPLOYEE_SKILL}mentors/` as const;
export const API_EMPLOYEE_ADVISORS = '/v3/employees/mentors/' as const;
export const API_EMPLOYEE_ADVISOR = `${API_EMPLOYEE_ADVISORS}{args.advisor_id}/` as const;
export const API_EMPLOYEE_ADVISEES = '/v3/mentors/employees/' as const;
export const API_EMPLOYEE_ADVISEE = `${API_EMPLOYEE_ADVISEES}{args.advisee_id}/` as const;
export const getApiSkillSetTarget = (skillId: string) => `${getApiEmployeeSkill(skillId)}set_target/` as const;
export const getApiSkillSelectCourses = (skillId: string) => `${getApiEmployeeSkill(skillId)}select_courses/` as const;
export const API_EMPLOYEE_LEARNING_PROGRESS = '/v3/employees/skills/with_course_hours/' as const;
export const API_EMPLOYEE_MENTORING = '/v3/employees/skills/mentoring/' as const;
export const API_SEND_MENTORSHIP_REQUEST = '/v3/employees/notifications/mentorship/send_request/' as const;
// Employee Jobs
export const API_EMPLOYEE_JOBS_TARGETED = '/v3/employees/jobs/targeted/' as const;
export const API_EMPLOYEE_JOBS_BASE = '/v3/employees/jobs/' as const;
export const API_EMPLOYEE_ROLE = `${API_EMPLOYEE_JOBS_BASE}{args.role_id}/` as const;
export const getApiEmployeeRole = (roleId: string) => `${API_EMPLOYEE_JOBS_BASE}${encodeURLParam(roleId)}/` as const;
export const getApiRoleSetTarget = (roleId: string) => `${getApiEmployeeRole(roleId)}set_target/` as const;
export const getApiSkillsGap = (roleId: string) => `${getApiEmployeeRole(roleId)}skills_gap/` as const;
export const getApiMatchRate = (roleId: string) => `${getApiEmployeeRole(roleId)}match_rate/` as const;
// Employee Dev Plans
export const API_EMPLOYEE_DEV_PLANS = '/v3/employees/development_plans/' as const;
export const API_EMPLOYEE_DEV_PLAN = `${API_EMPLOYEE_DEV_PLANS}{args.devplan_id}/` as const;
export const API_EMPLOYEE_PROGRESS = `${API_EMPLOYEE_DEV_PLAN}progress/` as const;
export const API_EMPLOYEE_DEV_PLAN_ACTIVE = `${API_EMPLOYEE_DEV_PLAN}set_active/` as const;
export const API_EMPLOYEE_COURSES = '/v3/employees/courses/' as const;
export const API_EMPLOYEE_COURSE = `${API_EMPLOYEE_COURSES}{args.course_id}/` as const;
export const API_EMPLOYEE_COURSE_COMPLETE = `${API_EMPLOYEE_COURSE}set_complete/` as const;

// Manager
export const API_MANAGER_DASHBOARD_EXPORT = `${API_BASE}/v3/manager/dashboard/export/` as const; // for <form POST> only!
export const API_SUPV_IN_DEMAND_SKILLS = '/v3/manager/dashboard/in_demand_skills/' as const;
export const API_SUPV_TARGETED_SKLS = '/v3/manager/dashboard/targeted_skills/' as const;
export const API_SUPV_TARGETED_JOBS = '/v3/manager/dashboard/targeted_jobs/' as const;
export const API_SUPV_JOBS_SKILLS_GAP = '/v3/manager/dashboard/most_frequent_jobs/' as const;
export const API_SUPV_TEAM = '/v3/manager/dashboard/team/' as const;
export const API_SUPV_TEAM_EXPORT = `${API_BASE}${API_SUPV_TEAM}export/` as const; // for <form POST> only!
const API_SUPV_EMPLOYEES = '/v3/manager/employees/' as const;
const API_SUPV_EMPLOYEE = `${API_SUPV_EMPLOYEES}{args.selected_employee_id}/` as const;
export const getApiEmployee = (employeeId: string) => `${API_SUPV_EMPLOYEES}${encodeURLParam(employeeId)}/` as const;
export const getApiEmployeeSkills = (employeeId: string) => `${getApiEmployee(employeeId)}skills/` as const;
export const API_SUPV_EMPLOYEE_ACTIVITIES = `${API_SUPV_EMPLOYEE}selected_activities/` as const;
export const getApiAddSuggestedSkill = (employeeId: string) => `${getApiEmployee(employeeId)}add_suggested_skill/` as const;
export const getApiRemoveSuggestedSkill = (employeeId: string) =>
  `${getApiEmployee(employeeId)}remove_suggested_skill/` as const;
export const API_SUPV_SKILLS = '/v3/manager/skills/' as const;
export const getApiSupvSkill = (skillId: string) => `${API_SUPV_SKILLS}${encodeURLParam(skillId)}/` as const;
export const getApiSupvSkillEmployees = (skillId: string) => `${getApiSupvSkill(skillId)}employees/` as const;
export const getApiSupvSkillTeams = (skillId: string) => `${getApiSupvSkill(skillId)}teams/` as const;
// unused: export const getApiSupvSkillSupply = (skillId: string) => `${getApiSupvSkill(skillId)}skill_supply/` as const;
export const API_SUPV_OPPORTUNITIES = '/v3/opportunity_marketplace/opportunities/' as const;
export const API_SUPV_OPPORTUNITY = `${API_SUPV_OPPORTUNITIES}{args.opportunity_id}/` as const;

// Talent Finder
const API_TALENT_FINDER = '/v3/talent_finder/' as const;
export const API_TALENT_FINDER_EMPLOYEES = `${API_TALENT_FINDER}employees/` as const;
export const API_TALENT_FINDER_EMPLOYEE = `${API_TALENT_FINDER_EMPLOYEES}{args.employee_id}/` as const;
export const API_TALENT_FINDER_COUNTS = `${API_TALENT_FINDER_EMPLOYEES}counts/` as const;
export const API_TALENT_FINDER_SEARCH = `${API_TALENT_FINDER_EMPLOYEES}search/` as const;
export const API_TALENT_FINDER_EXPORT = `${API_BASE}${API_TALENT_FINDER_EMPLOYEES}export/` as const;
// Cohorts
export const API_COHORTS = '/v3/cohorts/' as const;
export const API_COHORT = `${API_COHORTS}{args.cohort_id}/` as const;
// Development Plans
export const API_DEV_PLANS = '/v3/development_plans/' as const;
export const API_DEV_PLAN = `${API_DEV_PLANS}{args.devplan_id}/` as const;
export const API_DEV_PLAN_COURSES = `${API_DEV_PLAN}courses/` as const;
export const API_DEV_PLAN_COURSE = `${API_DEV_PLAN_COURSES}{args.course_id}/` as const;
export const API_DEV_PLAN_ADVISORS = `${API_DEV_PLAN}advisors/` as const;
export const API_DEV_PLAN_ADVISOR = `${API_DEV_PLAN_ADVISORS}{args.advisor_id}/` as const;
export const API_DEV_PLAN_OPPORTUNITIES = `${API_DEV_PLAN}opportunities/` as const;
export const API_DEV_PLAN_OPPORTUNITY = `${API_DEV_PLAN_OPPORTUNITIES}{args.opportunity_id}/` as const;
export const API_DEV_PLAN_EMPLOYEES = `${API_DEV_PLAN}employees/` as const;
export const API_DEV_PLAN_PROGRESSES = `${API_DEV_PLAN_EMPLOYEES}progress/` as const;
// Redeployment Plans
export const API_HR_REDEPLOYMENTS = '/v3/redeployment_plans/' as const;
export const API_HR_REDEPLOYMENT = `${API_HR_REDEPLOYMENTS}{args.plan_id}/` as const;
export const API_HR_REDEPLOYMENT_EMPLOYEES = `${API_HR_REDEPLOYMENT}employees/` as const;
export const API_HR_REDEPLOYMENT_EMPLOYEE_JOBS = `${API_HR_REDEPLOYMENT_EMPLOYEES}{args.employee_id}/best_match_jobs/` as const;
export const API_HR_REDEPLOYMENT_EMPLOYEE_JOB = `${API_HR_REDEPLOYMENT_EMPLOYEES}set_job/` as const;
export const API_HR_REDEPLOYMENT_EXPORT = `${API_BASE}${API_HR_REDEPLOYMENT_EMPLOYEES}export/` as const;

// HRBP & Leader
const API_HR_JOBS = '/v3/hr/jobs/' as const;
export const API_HR_JOBS_VOTE = `${API_HR_JOBS}vote/` as const;
export const API_HR_JOBS_ADVANCED = `${API_HR_JOBS}advanced/` as const;
export const getApiHrRole = (roleId: string) => `${API_HR_JOBS}${encodeURLParam(roleId)}/` as const;
export const getApiHrRoleVote = (roleId: string) => `${getApiHrRole(roleId)}vote/` as const;
export const getApiAdminHrRole = (roleId: string) => `${getApiHrRole(roleId)}skill_votes/` as const;
export const getApiAdminHrRoleVote = (roleId: string) => `${getApiHrRole(roleId)}vote_counts/` as const;
export const getApiAdminHrRoleSave = (roleId: string) => `${getApiHrRole(roleId)}skills/set_skills/` as const;
export const getApiMatchingJobEmpls = (roleId: string) => `${getApiHrRole(roleId)}matched_employees/` as const;
export const getApiMatchingJobEmplsCounts = (roleId: string) => `${getApiHrRole(roleId)}matched_employees_counts/` as const;
export const API_HR_ORG = '/v3/hr/org/' as const;
// HRBP Custom Activities
export const API_HR_CUSTOM_ACTIVITIES = '/v3/hr/activities/' as const;
export const API_HR_CUSTOM_ACTIVITY = `${API_HR_CUSTOM_ACTIVITIES}{args.activity_id}/` as const;
// Dashboard Analytics
export const API_HR_ANALYTICS = '/v3/hr/analytics/' as const;
export const API_DA_CHARTS_AVAILABILITY = `${API_HR_ANALYTICS}chartstatus/` as const;
export const API_DA_EXPORT = `${API_BASE}${API_HR_ANALYTICS}export/` as const;
export const API_DA_SKILL_SUPPLY = `${API_HR_ANALYTICS}skillsupplydemand/` as const;
export const API_DA_SKILL_SUPPLY_EXPORT = `${API_DA_EXPORT}skillsupplydemand/` as const;
export const API_DA_SKILLS_GROWTH = `${API_HR_ANALYTICS}skillgrowth/` as const;
export const API_DA_SKILLS_GROWTH_EXPORT = `${API_DA_EXPORT}skillgrowth/` as const;
export const API_DA_SKILLS_DEVELOPMENT = `${API_HR_ANALYTICS}skilldevelopment/` as const;
export const API_DA_SKILLS_DEVELOPMENT_EXPORT = `${API_DA_EXPORT}skilldevelopment/` as const;
export const API_DA_EFFECTIVE_COURSES = `${API_HR_ANALYTICS}mosteffectivecourse/` as const;
export const API_DA_EFFECTIVE_COURSES_EXPORT = `${API_DA_EXPORT}mosteffectivecourse/` as const;
export const API_DA_COURSE_COMPLETION = `${API_HR_ANALYTICS}coursecompletion/` as const;
export const API_DA_COURSE_COMPLETION_EXPORT = `${API_DA_EXPORT}coursecompletion/` as const;
export const API_DA_EFFECTIVE_ADVISORS = `${API_HR_ANALYTICS}mosteffectivementors/` as const;
export const API_DA_EFFECTIVE_ADVISORS_EXPORT = `${API_DA_EXPORT}mosteffectivementors/` as const;
export const API_DA_EMPLOYEE_SKILLS = `${API_HR_ANALYTICS}employeeconfirmed/` as const;
export const API_DA_EMPLOYEE_SKILLS_EXPORT = `${API_DA_EXPORT}employeeconfirmed/` as const;
export const API_DA_SKILL_COURSE = `${API_HR_ANALYTICS}skillorcourse/` as const;
export const API_DA_SKILL_COURSE_EXPORT = `${API_DA_EXPORT}skillorcourse/` as const;
export const API_DA_SKILLS_INSIGHTS = `${API_HR_ANALYTICS}skillinsight/` as const;
export const API_DA_SKILLS_INSIGHTS_EXPORT = `${API_DA_EXPORT}skillinsight/` as const;
export const API_DA_SKILLS_INSIGHTS_INDEMAND = `${API_HR_ANALYTICS}skillinsightindemand/` as const;
export const API_DA_SKILLS_INSIGHTS_INDEMAND_EXPORT = `${API_DA_EXPORT}skillinsightindemand/` as const;
export const API_DA_JOBS_MOVEMENT = `${API_HR_ANALYTICS}jobmovement/` as const;
export const API_DA_JOBS_MOVEMENT_EXPORT = `${API_DA_EXPORT}jobmovement/` as const;
export const API_DA_HIRING_TRENDS = `${API_HR_ANALYTICS}jobhire/` as const;
export const API_DA_HIRING_TRENDS_EXPORT = `${API_DA_EXPORT}jobhire/` as const;
export const API_DA_CLOSE_MATCH_JOBS = `${API_HR_ANALYTICS}closematchjob/` as const;
export const API_DA_CLOSE_MATCH_JOBS_EXPORT = `${API_DA_EXPORT}closematchjob/` as const;
export const API_DA_AVERAGE_SKILLS = `${API_HR_ANALYTICS}avgnumberskill/` as const;
export const API_DA_AVERAGE_SKILLS_EXPORT = `${API_DA_EXPORT}avgnumberskill/` as const;
export const API_DA_EMPLOYEE_VELOCITY = `${API_HR_ANALYTICS}employeevelocity/` as const;
export const API_DA_EMPLOYEE_VELOCITY_EXPORT = `${API_DA_EXPORT}employeevelocity/` as const;
export const API_DA_CURRENT_ROLE_MATCH = `${API_HR_ANALYTICS}currentrolematch/` as const;
export const API_DA_CURRENT_ROLE_MATCH_EXPORT = `${API_DA_EXPORT}currentrolematch/` as const;
export const API_DA_JOB_MOVEMENT_SKILLS = `${API_HR_ANALYTICS}jobmovementskill/` as const;
export const API_DA_JOB_MOVEMENT_SKILLS_EXPORT = `${API_DA_EXPORT}jobmovementskill/` as const;
export const API_DA_JOB_RELATED_SKILLS = `${API_HR_ANALYTICS}jobrelatedskill/` as const;
export const API_DA_JOB_RELATED_SKILLS_EXPORT = `${API_DA_EXPORT}jobrelatedskill/` as const;
export const API_DA_UPSKILLING_VELOCITY = `${API_HR_ANALYTICS}employeevelocityupskill/` as const;
export const API_DA_UPSKILLING_VELOCITY_EXPORT = `${API_DA_EXPORT}employeevelocityupskill/` as const;
export const API_DA_USAGE_BY_ORG = `${API_HR_ANALYTICS}orgusage/` as const;
export const API_DA_USAGE_BY_ORG_EXPORT = `${API_DA_EXPORT}orgusage/` as const;
// Delegation
const API_HR_EMPLOYEES = '/v3/hr/employees/' as const;
export const API_LOOKUP_LEADERS = `${API_HR_EMPLOYEES}leaders/` as const;
export const API_LOOKUP_DELEGATES = `${API_HR_EMPLOYEES}delegates/` as const;
export const API_DELEGATES = '/v3/hr/delegates/' as const;
export const API_DELEGATES_ADD = `${API_DELEGATES}add/` as const;
export const API_DELEGATES_REMOVE = `${API_DELEGATES}remove/` as const;

// Admin
export const API_ADMIN_PASSWORDS = '/v3/admin/passwords/' as const;
// export const API_ADMIN_USER_INVITE = '/v3/admin/users/invite/' as const;
export const API_ADMIN_USERS = '/v3/admin/entities/users/' as const;
export const API_ADMIN_USER = `${API_ADMIN_USERS}{args.user_id}/` as const;
export const API_ADMIN_REPORTS = '/v3/admin/entities/reports/' as const;
export const API_ADMIN_REPORT = `${API_ADMIN_REPORTS}{args.report_id}/` as const;
export const API_ADMIN_JOBS = '/v3/admin/entities/jobs/' as const;
export const API_ADMIN_JOB = `${API_ADMIN_JOBS}{args.job_id}/` as const;
export const API_ADMIN_EMPLOYEES = '/v3/admin/entities/employees/' as const;
export const API_ADMIN_EMPLOYEE = `${API_ADMIN_EMPLOYEES}{args.employee_id}/` as const;
export const getApiAdminImpersonation = (code: string) => `/token-by-impersonation/${code}/` as const;

// Team Builder
export const API_TEAM_BUILDER_TEAMS = '/v3/team_builder/teams/' as const;
export const getApiTeamBuilderTeam = (teamId: number) => `${API_TEAM_BUILDER_TEAMS}${encodeURLParam(teamId)}/` as const;
// Team Builder - Jobs
export const getApiTeamBuilderJobs = (teamId: number) => `${getApiTeamBuilderTeam(teamId)}jobs/` as const;
export const getApiTeamBuilderJob = (teamId: number, jobId: number) =>
  `${getApiTeamBuilderJobs(teamId)}${encodeURLParam(jobId)}/` as const;
// Team Builder - Employees & Matches
export const getApiTeamBuilderEmployees = (teamId: number) => `${getApiTeamBuilderTeam(teamId)}employees/` as const;
export const getApiTeamBuilderEmployee = (teamId: number, employeeId: number) =>
  `${getApiTeamBuilderEmployees(teamId)}${encodeURLParam(employeeId)}/` as const;
export const getApiTeamBuilderMatches = (teamId: number) => `${getApiTeamBuilderTeam(teamId)}matches/` as const;
// Team Builder - Skills
export const getApiTeamBuilderSkills = (teamId: number) => `${getApiTeamBuilderTeam(teamId)}skills/` as const;
export const getApiTeamBuilderSkill = (teamId: number, skillId: number) =>
  `${getApiTeamBuilderSkills(teamId)}${encodeURLParam(skillId)}/` as const;
export const getApiTeamBuilderSkillsReset = (teamId: number) => `${getApiTeamBuilderSkills(teamId)}reset/` as const;

// Axios instance with base API URL
export const axiosInstance = axios.create({
  baseURL: API_BASE
});

function useApiUrls() {
  const { HAS_LOGIN } = useCustomerSettings();
  return useMemo(() => ({
    API_USER_TOKEN_BY_SSO: HAS_LOGIN ? API_USER_TOKEN_BY_SSO_DEFAULT : API_USER_TOKEN_BY_SSO_SSO
  }), [HAS_LOGIN]);
}

export default useApiUrls;
