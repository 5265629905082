import { memo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isString from 'lodash/isString';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedNumber } from 'react-intl';
// local imports
import { AdminJob } from '../graphql/types';
import { Job } from '../models/job';
import { CareerPath } from '../models/careerPath';

type JobLevelProps = {
  role?: Job | AdminJob | CareerPath | null;
};

const JobLevelPropTypes = {
  role: PropTypes.object as Validator<Job>
};

const JobLevel: FunctionComponent<JobLevelProps> = ({
  role
}) => {
  const { display_level } = (role || {}) as Job | CareerPath;
  const { display_management_level } = (role || {}) as AdminJob;
  return (display_level && isString(display_level) && (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {display_level}
    </>
  )) || (display_management_level && isString(display_management_level) && (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {display_management_level}
    </>
  )) || (
    <FormattedNumber value={toSafeInteger(role?.management_level)}/>
  );
};

JobLevel.propTypes = JobLevelPropTypes;

export default memo(JobLevel);
